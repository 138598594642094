<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <h3 class="my-8">
      Edit CBT
    </h3>
    <v-card>
      <v-card-text class="pa-8">
        <div class="mb-10">
          <h3 class="mb-1">
            Informasi CBT
          </h3>
          <span class="text-subtitle-2 font-weight-light">Informasi untuk CBT</span>
        </div>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <div class="mb-4">
              <FileInput
                v-model="initialThumbnail"
                :prependicon="icons.mdiImageMultipleOutline"
                label="Thumbnail"
                outlined
                dense
                @input="getFileThumbnail"
              ></FileInput>
            </div>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="cbt.name"
                label="Nama CBT"
                outlined
                dense
              >
              </v-text-field>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-menu
                v-model="date"
                :close-on-content-click="false"
                nudge-right="0"
                nudge-top="20"
                max-width="100%"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_date"
                    label="Tanggal"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    @click="autofillDate"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start_date"
                  :first-day-of-week="0"
                  locale="id-id"
                  color="primary"
                  @input="date = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            cols="6"
            class="py-0"
          >
            <div>
              <v-text-field
                v-model="cbt.duration"
                label="Duration"
                type="number"
                outlined
                dense
              ></v-text-field>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                ref="menu_start"
                v-model="menu_start"
                :close-on-content-click="false"
                nudge-right="32"
                nudge-top="40"
                :return-value.sync="cbt.start_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="start_time"
                    label="Jam Mulai"
                    :prepend-icon="icons.mdiClockOutline"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu_start"
                  v-model="start_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_start.save(cbt.start_time)"
                ></v-time-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            xl="6"
            lg="6"
            md="6"
            sm="6"
            cols="12"
            class="py-0"
          >
            <div>
              <v-menu
                ref="menu_end"
                v-model="menu_end"
                :close-on-content-click="false"
                nudge-right="32"
                nudge-top="40"
                :return-value.sync="cbt.end_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="end_time"
                    label="Jam Selesai"
                    :prepend-icon="icons.mdiClockOutline"
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu_end"
                  v-model="end_time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.menu_end.save(cbt.end_time)"
                ></v-time-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col md="12">
            <div class="mb-4">
              <h3 class="mb-1">
                Instruksi CBT
              </h3>
              <span class="text-subtitle-2 font-weight-light">Berisi informasi ketetentuan CBT (Contoh: Jumlah Soal, Durasi, Nilai, Tata Tertib, dll)</span>
            </div>
            <div class="mt-6">
              <CKEditor
                v-model="cbt.instruction"
                :value="cbt.instruction"
                outlined
                dense
              ></CKEditor>
            </div>
          </v-col>

        </v-row>
        <v-card-actions class="d-flex justify-center my-8 pa-0">
          <v-btn
            color="primary"
            large
            :loading="isLoadingButton"
            @click="updateCBT()"
          >
            Update
          </v-btn>
          <v-btn
            color="primary"
            large
            outlined
            @click="cancelAction"
          >
            Batal
          </v-btn>
        </v-card-actions>
        <div class="mb-4">
          <div class="mb-4">
            <h3 class="mb-1">
              Leaderboard CBT
            </h3>
            <span class="text-subtitle-2 font-weight-light">Akan menampilkan leaderboard hasil CBT jika diaktifkan</span>
          </div>
          <div class="d-flex align-center">
            <v-switch
              v-model="leaderboard"
              class="ma-0 pa-0"
              hide-details="none"
              @change="handleSwitch($event)"
            ></v-switch>
            <span class="ms-4">Tampilkan Leaderboard CBT</span>
          </div>
        </div>
        <div class="my-6">
          <h3 class="mb-1">
            Soal
          </h3>
          <span class="text-subtitle-2 font-weight-light">Soal untuk CBT</span>
          <v-divider class="my-6"></v-divider>

          <div class="mb-8">
            <v-card
              v-for="questionItem,index in questionItems"
              v-show="cbt.question.length"
              :key="index"
              class="mb-2"
            >
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <v-row class="align-center">
                      <v-col md="2">
                        <span>Soal No {{ index + 1 }}</span>
                      </v-col>
                      <v-col md="3">
                        <span></span>
                        <v-chip
                          outlined
                          class="v-chip-light-bg success--text"
                          h-e-a-d
                          @click="checkDescription(questionItem.name)"
                        >
                          Lihat Deskripsi
                        </v-chip>
                      </v-col>
                      <v-col md="5">
                        <div
                          v-if="isValidURL(questionItem.answer.answer)"
                          class="d-flex align-center"
                        >
                          Kunci Jawaban :
                          <div class="ms-2">
                            <!-- Image -->
                            <v-img
                              :src="questionItem.answer.answer"
                              width="40"
                            ></v-img>
                          </div>
                        </div>
                        <span
                          v-else
                          class="text-truncate"
                        >
                          Kunci Jawaban :
                          <span class="ms-2">{{ questionItem.answer.answer }}</span>
                        </span>
                      </v-col>
                      <v-col md="1">
                        <a
                          class="text-decoration-none"
                          @click.prevent="showFormEditQuestion(questionItem.uuid)"
                        >Edit</a>
                      </v-col>
                      <v-col md="1">
                        <a
                          class="text-decoration-none"
                          @click.prevent="confirmDestroyQuestion(questionItem.uuid)"
                        >Hapus</a>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p>Pembahasan</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card>
          </div>
          <div
            v-for="(question, index) of questions"
            :key="index"
            class="mb-4"
          >
            <div>
              <div class="d-flex justify-space-between align-center mb-5">
                <div>
                  <h4>
                    Soal Nomor {{ questionItems.length > 0 ? questionItems.length + (index+1) : index+1 }}
                  </h4>
                </div>
                <div>
                  <v-btn
                    color="primary pa-2"
                    icon
                    small
                    @click="closeFormQuestion(index)"
                  >
                    <v-icon>
                      {{ icons.mdiCloseCircleOutline }}
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="mb-4">
                <CKEditor
                  :value="question[index].name"
                  label="Content"
                  outlined
                  dense
                  @input="handleCKeditor($event, index)"
                ></CKEditor>
              </div>
            </div>
            <div>
              <h4 class="mb-4">
                Masukkan Gambar (jika ada)
              </h4>
              <FileInput
                :value="questionImage[index]"
                label="Gambar"
                outlined
                dense
                :disabled="false"
                :prependicon="icons.mdiImageMultipleOutline"
                @input="getFile"
              ></FileInput>
            </div>
            <div>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <h4 class="mb-5 mt-8">
                    Kunci Jawaban
                  </h4>
                  <v-row>
                    <v-col
                      v-for="questionOption, index in question[index].questionOptions"
                      :key="index"
                      cols="12"
                      md="6"
                      lg="6"
                      xl="6"
                      class="py-2"
                    >
                      <div>
                        <v-text-field
                          v-model="questionOption.choice"
                          :disabled="questionOption.file ? true : false"
                          label="Jawaban"
                          outlined
                          dense
                        >
                          <span
                            slot="prepend"
                            class="pt-1"
                          >
                            {{ questionOption.title }}
                          </span>
                        </v-text-field>
                      </div>
                      <div class="ms-4">
                        <FileInput
                          v-model="questionOption.file"
                          :value="questionOption.file"
                          label="Gambar"
                          outlined
                          dense
                          :disabled="questionOption.choice ? true : false"
                          :prependicon="icons.mdiImageMultipleOutline"
                          @input="getFileQuestion"
                        ></FileInput>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-0"
                >
                  <h4 class="my-4">
                    Jawaban Yang Benar
                  </h4>
                  <div class="">
                    <v-autocomplete
                      v-model="question[index].answer"
                      label="Jawaban yang benar"
                      outlined
                      :items="answers"
                      item-text="value"
                      dense
                    >
                    </v-autocomplete>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-0"
                >
                  <h4 class="my-4">
                    Point Jawaban Yang Benar
                  </h4>
                  <div>
                    <v-text-field
                      v-model="question[index].point"
                      label="Point"
                      type="number"
                      outlined
                      dense
                    >
                    </v-text-field>
                  </div>
                </v-col>
              </v-row>
            </div>
          </div>

          <div class="mb-8 mt-8 d-flex justify-start align-center">
            <v-btn
              text
              small
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="showFormAddQuestion"
            >
              <v-icon
                color="primary"
                small
                class="me-2"
              >
                {{ icons.mdiPlus }}
              </v-icon> TAMBAH SOAL
            </v-btn>
            <v-btn
              text
              small
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="showFormImportQuestion"
            >
              <v-icon
                color="primary"
                small
                class="me-2"
              >
                {{ icons.mdiFileOutline }}
              </v-icon> IMPORT SOAL
            </v-btn>
            <v-btn
              text
              small
              class="text-decoration-none primary--text font-weight-medium me-8 me-sm-10 me-md-15 me-lg-15 me-xl-15 text-caption"
              @click="exportQuestion"
            >
              <v-icon
                color="primary"
                small
                class="me-2"
              >
                {{ icons.mdiTrayArrowDown }}
              </v-icon> DOWNLOAD TEMPLATE
            </v-btn>
          </div>
          <v-divider></v-divider>
        </div>
        <v-card-actions
          v-if="isHide"
          class="d-flex justify-center my-8"
        >
          <v-btn
            color="primary"
            large
            :loading="isLoadingButton"
            @click="addQuestion"
          >
            Tambah
          </v-btn>
          <v-btn
            color="primary"
            large
            outlined
            @click="cancelAction"
          >
            Batal
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
    <ModalDialog
      :visible="modalDialog"
      header="Edit Soal"
      text-button="Update"
      :is-loading-button="isLoadingButton"
      @handler-button="updateQuestion"
      @close-modal="modalDialog = false"
      @close-button="closeModalQuestion"
    >
      <template v-slot:body>
        <div class="mb-4">
          <CKEditor
            :value="initialContent"
            label="Soal"
            outlined
            dense
            @input="handleCKeditor($event, 'edit')"
          ></CKEditor>
        </div>
        <div>
          <h4 class="mb-4">
            Masukkan Gambar (jika ada)
          </h4>
          <FileInput
            v-model="initialFile"
            label="Gambar"
            outlined
            dense
            :disabled="false"
            :prependicon="icons.mdiImageMultipleOutline"
            @input="getFileImport"
          ></FileInput>
        </div>
        <div>
          <v-row>
            <v-col
              cols="12"
              class="py-0"
            >
              <h4 class="mb-5 mt-8">
                Kunci Jawaban
              </h4>
              <v-row>
                <!-- <v-col
                  v-for="questionOption, index in questionOptions"
                  :key="index"
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionOption.choice"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        {{ questionOption.title }}
                      </span>
                    </v-text-field>
                  </div>
                  <div class="ms-4">
                    <FileInput
                      v-model="initalFileQuestion"
                      label="Gambar"
                      outlined
                      dense
                      :prependicon="icons.mdiImageMultipleOutline"
                      @input="getFileQuestion"
                    ></FileInput>
                  </div>
                </v-col> -->
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_a"
                      :disabled="questionEdit.file_a ? true : false"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        A.
                      </span>
                    </v-text-field>
                  </div>
                  <div class="ms-4">
                    <FileInput
                      :value="initialChoiceA"
                      :disabled="questionEdit.choice_a ? true : false"
                      label="Thumbnail"
                      outlined
                      :prependicon="icons.mdiImageMultipleOutline"
                      dense
                      @input="getFileChoiceA"
                    ></FileInput>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_b"
                      :disabled="questionEdit.file_b ? true : false"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        B.
                      </span>
                    </v-text-field>
                  </div>
                  <div class="ms-4">
                    <FileInput
                      :value="initialChoiceB"
                      :disabled="questionEdit.choice_b ? true : false"
                      label="Thumbnail"
                      outlined
                      :prependicon="icons.mdiImageMultipleOutline"
                      dense
                      @input="getFileChoiceB"
                    ></FileInput>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_c"
                      :disabled="questionEdit.file_c ? true : false"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        C.
                      </span>
                    </v-text-field>
                  </div>
                  <div class="ms-4">
                    <FileInput
                      :value="initialChoiceC"
                      :disabled="questionEdit.choice_c ? true : false"
                      label="Thumbnail"
                      outlined
                      :prependicon="icons.mdiImageMultipleOutline"
                      dense
                      @input="getFileChoiceC"
                    ></FileInput>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                  class="py-2"
                >
                  <div>
                    <v-text-field
                      v-model="questionEdit.choice_d"
                      :disabled="questionEdit.file_d ? true : false"
                      label="Jawaban"
                      outlined
                      dense
                    >
                      <span
                        slot="prepend"
                        class="pt-1"
                      >
                        D.
                      </span>
                    </v-text-field>
                  </div>
                  <div class="ms-4">
                    <FileInput
                      :value="initialChoiceD"
                      :disabled="questionEdit.choice_d ? true : false"
                      label="Thumbnail"
                      outlined
                      :prependicon="icons.mdiImageMultipleOutline"
                      dense
                      @input="getFileChoiceD"
                    ></FileInput>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="my-4">
                Jawaban Yang Benar
              </h4>
              <div class="">
                <v-autocomplete
                  v-model="questionEdit.answer.answer"
                  label="Jawaban yang benar"
                  outlined
                  :items="answers"
                  item-text="value"
                  dense
                >
                </v-autocomplete>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="6"
              lg="6"
              xl="6"
              class="py-0"
            >
              <h4 class="my-4">
                Point Jawaban Yang Benar
              </h4>
              <div>
                <v-text-field
                  v-model="questionEdit.answer.point"
                  label="Point"
                  type="number"
                  outlined
                  dense
                >
                </v-text-field>
              </div>
            </v-col>
          </v-row>
        </div>
      </template>
    </ModalDialog>

    <ModalDialog
      :visible="modalDialogImport"
      header="Import Soal"
      text-button="Import"
      :is-loading-button="isLoadingButton"
      @handler-button="importQuestion"
      @close-modal="modalDialogImport = false"
      @close-button="modalDialogImport = false"
    >
      <template v-slot:body>
        <FileInput
          v-model="importFile"
          label="File"
          outlined
          dense
          :disabled="false"
          :accept="accept"
          :prependicon="icons.mdiImageMultipleOutline"
          @input="getFile"
        ></FileInput>
      </template>
    </ModalDialog>
    <ModalDialog
      :visible="modalDescription"
      :width="widthModal"
      header="Deskripsi Soal"
      :hidden-actions="false"
      @close-modal="modalDescription = false"
    >
      <template v-slot:body>
        <p v-html="detail"></p>
      </template>
      <template v-slot:footer>
        <v-btn
          color="primary"
          outlined
          large
          @click="modalDescription = false"
        >
          Tutup
        </v-btn>
      </template>
    </ModalDialog>
    <ConfirmDialog
      :visible="confirmDialog"
      :is-loading-button="isLoadingButton"
      header="Hapus Soal"
      @close-button="confirmDialog = false"
      @destroy-button="destroyQuestion"
    >
    </ConfirmDialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import CKEditor from '@/views/components/CkEditor.vue'
import ConfirmDialog from '@/views/components/ConfirmDialog.vue'
import FileInput from '@/views/components/FileInput.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'
import {
  mdiArrowLeft,
  mdiClockOutline,
  mdiCloseCircleOutline,
  mdiDotsVertical,
  mdiFileOutline,
  mdiImageMultipleOutline,
  mdiPlus,
  mdiTrayArrowDown,
} from '@mdi/js'

export default {
  name: 'CbtAdd',
  components: {
    FileInput,
    ModalDialog,
    ConfirmDialog,
    CKEditor,
  },
  data() {
    return {
      modalDescription: false,
      widthModal: 810,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiFileOutline,
        mdiClockOutline,
        mdiDotsVertical,
        mdiTrayArrowDown,
        mdiCloseCircleOutline,
        mdiImageMultipleOutline,
      },
      isLoadingButton: false,
      isDisabledButton: false,
      answers: [
        { index: 'a', value: 'A' },
        { index: 'b', value: 'B' },
        { index: 'c', value: 'C' },
        { index: 'd', value: 'D' },
      ],

      editAnswers: [],
      question: [],
      questions: [],
      questionImage: [],
      questionItems: [],
      questionItem: {},
      questionEdit: {
        name: null,
        number: null,
        choice_a: '',
        choice_b: '',
        choice_c: '',
        choice_d: '',
        file_a: null,
        file_b: null,
        file_c: null,
        file_d: null,
        answer: {
          answer: '',
          point: null,
        },
      },
      questionContent: [],
      questionOptions: [],
      numberQuestion: 0,
      modalDialog: false,
      modalDialogImport: false,
      confirmDialog: false,
      isHide: false,
      date: false,
      service: 'cbt',
      cbt: {},
      cbt_update: {
        uuid: '',
        thumbnail: null,
        name: '',
        duration: '',
        start_date: null,
        end_date: null,
        type: 'school',
        type_uuid: '',
        instruction: '',
        show_leaderboard: 1,
      },
      leaderboard: false,
      start_date: '',
      start_time: '',
      end_time: '',
      time: null,
      menu_start: false,
      menu_end: false,
      tempContent: '',
      tempImage: [],
      detail: '',
      importFile: null,
      questionFile: [],
      accept: '.xlsx',
    }
  },
  computed: {
    initialFile: {
      get() {
        if (typeof this.questionEdit.image === 'string') {
          return new File([this.questionEdit.image], this.questionEdit.image, { type: 'text/plain' })
        }

        return this.questionEdit.image
      },
      set() {
        return this.questionEdit.image
      },
    },
    initialThumbnail: {
      get() {
        if (typeof this.cbt.thumbnail === 'string') {
          return new File([this.cbt.thumbnail], this.cbt.thumbnail, { type: 'text/plain' })
        }

        return this.cbt.thumbnail
      },
      set() {
        return this.cbt.thumbnail
      },
    },
    initialChoiceA: {
      get() {
        if (typeof this.questionEdit.file_a === 'string' && this.checkURL(this.questionEdit.file_a)) {
          return new File([this.questionEdit.file_a], this.questionEdit.file_a, { type: 'text/plain' })
        }

        return this.questionEdit.file_a
      },
      set() {
        return this.questionEdit.file_a
      },
    },
    initialChoiceB: {
      get() {
        if (typeof this.questionEdit.file_b === 'string' && this.checkURL(this.questionEdit.file_b)) {
          return new File([this.questionEdit.file_b], this.questionEdit.file_b, { type: 'text/plain' })
        }

        return this.questionEdit.file_b
      },
      set() {
        return this.questionEdit.file_b
      },
    },
    initialChoiceC: {
      get() {
        if (typeof this.questionEdit.file_c === 'string' && this.checkURL(this.questionEdit.file_c)) {
          return new File([this.questionEdit.file_c], this.questionEdit.file_c, { type: 'text/plain' })
        }

        return this.questionEdit.file_c
      },
      set() {
        return this.questionEdit.file_c
      },
    },
    initialChoiceD: {
      get() {
        if (typeof this.questionEdit.file_d === 'string' && this.checkURL(this.questionEdit.file_d)) {
          return new File([this.questionEdit.file_d], this.questionEdit.file_d, { type: 'text/plain' })
        }

        return this.questionEdit.file_d
      },
      set() {
        return this.questionEdit.file_d
      },
    },
    initialContent: {
      get() {
        if (this.questionEdit.name === null) {
          return ''
        }

        return this.questionEdit.name
      },
      set() {
        return this.questionEdit.name
      },
    },
    initalFileQuestion: {
      get() {
        // if (typeof this.questionEdit.image === 'string') {
        //   return new File([this.questionEdit.image], this.questionEdit.image, { type: 'text/plain' })
        // }

        // return this.questionOption.choice
        return 'a'
      },
    },
  },
  mounted() {
    this.getCbt(this.$route.params.uuid)
  },

  methods: {
    checkDescription(data) {
      this.detail = data

      this.modalDescription = true
    },
    cancelAction() {
      this.modalDialog = false
      const image = this.tempContent.split('"')
      image.forEach(async item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const fileName = {
            file: img,
          }
          await this.deleteStorage(fileName)
        }
      })
      this.tempContent = ''

      return this.$router.back()
    },
    async deleteStorage(fileName) {
      await this.$services.ApiServices.deleteStorage('news', fileName).then(({ data }) => true)
    },
    handleCKeditor(event, index) {
      if (index !== 'edit') {
        this.question[index].name = event
      } else {
        this.questionEdit.name = event
      }

      if (this.tempContent.length < event.length) {
        this.tempContent = event
      }
    },
    getFile(data) {
      this.questionImage.push(data)
    },
    getFileImport(data) {
      this.importFile = data
    },
    getFileThumbnail(data) {
      this.cbt.thumbnail = data
    },
    getFileChoiceA(data) {
      this.questionEdit.file_a = data
    },
    getFileChoiceB(data) {
      this.questionEdit.file_b = data
    },
    getFileChoiceC(data) {
      this.questionEdit.file_c = data
    },
    getFileChoiceD(data) {
      this.questionEdit.file_d = data
    },
    getFileQuestion(data) {
      this.questionFile.push(data)
    },
    returnBack() {
      return this.$router.back()
    },
    autofillDate() {
      this.cbt.date = new Date().toISOString().substr(0, 10)
    },
    showFormAddQuestion() {
      this.tempContent = ''
      this.tempImage = []
      this.question = [
        ...this.question,
        {
          name: '',
          number: 0,
          image: null,
          choice_a: null,
          choice_b: null,
          choice_c: null,
          choice_d: null,
          answer: '',
          point: '',
          questionOptions: [
            {
              index: 1,
              vModel: 'choice_a',
              title: 'A',
              choice: '',
              file: null,
            },
            {
              index: 2,
              vModel: 'choice_b',
              title: 'B',
              choice: '',
              file: null,
            },
            {
              index: 3,
              vModel: 'choice_c',
              title: 'C',
              choice: '',
              file: null,
            },
            {
              index: 4,
              vModel: 'choice_d',
              title: 'D',
              choice: '',
              file: null,
            },
          ],
        },
      ]

      this.question.number = this.questionItems.length + 1
      this.questions.push(this.question)
      this.isHide = true
    },
    showFormImportQuestion() {
      this.modalDialogImport = true
    },
    async getCbt(uuid) {
      await this.$services.ApiServices.get(this.service, uuid).then(
        ({ data }) => {
          this.cbt = data.data
          this.cbt.uuid = data.data.uuid
          this.start_date = this.cbt.start_date.slice(0, 10)
          this.start_time = this.cbt.start_date.slice(11, 16)
          this.end_time = this.cbt.end_date.slice(11, 16)
          this.cbt.instruction = data.data.instruction
          this.leaderboard = data.data.show_leaderboard
          this.questionItems = this.cbt.question
        },
        err => console.error(err),
      )
    },
    async addQuestion() {
      this.isLoadingButton = true
      this.isDisabledButton = true

      if (this.cbt.uuid === '') {
        await this.addCbt()
      }
      await this.question.forEach(async (item, index) => {
        item.number = this.cbt.question.length + (index + 1)
        item.cbt_uuid = this.$route.params.uuid
        this.formData = new FormData()
        this.formData.append('cbt_uuid', item.cbt_uuid)
        this.formData.append('number', item.number)
        this.formData.append('name', item.name)
        this.formData.append('image', this.questionImage[index])
        item.questionOptions.forEach(el => {
          this.formData.append(el.vModel, el.choice !== '' ? el.choice : el.file)
        })
        this.formData.append('answer', item.answer)
        this.formData.append('point', item.point)
        if (typeof this.questionImage[index] === 'undefined') {
          this.formData.delete('image')
        }
        const tempImage = this.tempContent.split('"')
        tempImage.forEach(async item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            this.tempImage.push(img)
          }
        })
        const image = item.name.split('"')
        image.forEach(item => {
          const img = item.split('/').pop()
          if (img.length === 44) {
            const carIndex = this.tempImage.indexOf(img)
            this.tempImage.splice(carIndex, 1)

            this.tempImage.forEach(async item => {
              await this.deleteStorage({ file: item })
            })
          }
        })
        await this.$services.ApiServices.add('questioncbt', this.formData).then(
          ({ data }) => {
            this.showSnackbar({
              text: data.result.message,
              color: 'success',
            })
          },
          err => {
            this.isLoadingButton = false
            console.error(err)
          },
        )
        await this.getCbt(this.$route.params.uuid)
        this.isHide = false
        this.numberQuestion = this.questionItems.length
        this.questions.splice(index, this.questions.length)
        if (this.questions.length < 1) {
          this.isHide = false
        }
        this.question = []
        this.questionImage = []
        this.isLoadingButton = false
        this.isDisabledButton = false
      })
    },
    showFormEditQuestion(uuid) {
      this.tempContent = ''
      this.tempImage = []
      this.questionEdit.uuid = uuid

      this.$services.ApiServices.get('questioncbt', uuid).then(
        ({ data }) => {
          this.questionEdit.name = data.data.name
          this.questionEdit.number = data.data.number
          this.questionEdit.answer.point = data.data.answer.point
          this.checkURL(data.data.choice_a)
            ? (this.questionEdit.file_a = data.data.choice_a.split('/').pop())
            : (this.questionEdit.choice_a = data.data.choice_a)
          this.checkURL(data.data.choice_b)
            ? (this.questionEdit.file_b = data.data.choice_b.split('/').pop())
            : (this.questionEdit.choice_b = data.data.choice_b)
          this.checkURL(data.data.choice_c)
            ? (this.questionEdit.file_c = data.data.choice_c.split('/').pop())
            : (this.questionEdit.choice_c = data.data.choice_c)
          this.checkURL(data.data.choice_d)
            ? (this.questionEdit.file_d = data.data.choice_d.split('/').pop())
            : (this.questionEdit.choice_d = data.data.choice_d)
          const image = data.data.name.split('"')
          image.forEach(async item => {
            const img = item.split('/').pop()
            if (img.length === 44) {
              this.tempImage.push(img)
            }
          })
          this.modalDialog = true
        },
        err => console.error(err),
      )
    },
    async updateCBT() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('name', this.cbt.name)
      this.formData.append('duration', this.cbt.duration)
      this.formData.append('type', 'school')
      this.formData.append('type_uuid', this.cbt.type.uuid)
      this.formData.append('start_date', `${this.start_date} ${this.start_time}`)
      this.formData.append('end_date', `${this.start_date} ${this.end_time}`)
      this.formData.append('show_leaderboard', this.leaderboard === true ? '1' : '0')
      if (typeof this.cbt.thumbnail !== 'string' && this.cbt.thumbnail !== null) {
        this.formData.append('thumbnail', this.cbt.thumbnail)
      }
      this.formData.append('instruction', this.cbt.instruction)
      await this.$services.ApiServices.update(this.service, this.formData, this.cbt.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
        },
      )
      this.isLoadingButton = false
    },
    async updateQuestion() {
      this.isLoadingButton = true
      this.isDisabledButton = true

      this.formData = new FormData()
      this.formData.append('number', this.questionEdit.number)
      this.formData.append('name', this.questionEdit.name)
      this.formData.append(
        'choice_a',
        this.questionEdit.choice_a !== '' ? this.questionEdit.choice_a : this.questionEdit.file_a,
      )
      this.formData.append(
        'choice_b',
        this.questionEdit.choice_b !== '' ? this.questionEdit.choice_b : this.questionEdit.file_b,
      )
      this.formData.append(
        'choice_c',
        this.questionEdit.choice_c !== '' ? this.questionEdit.choice_c : this.questionEdit.file_c,
      )
      this.formData.append(
        'choice_d',
        this.questionEdit.choice_d !== '' ? this.questionEdit.choice_d : this.questionEdit.file_d,
      )

      if (this.questionEdit.answer.answer !== '') {
        this.formData.append('answer', this.questionEdit.answer.answer)
      }

      this.formData.append('point', this.questionEdit.answer.point)

      if (typeof this.questionEdit.image !== 'string') {
        this.formData.delete('image')
      }

      if (typeof this.formData.get('choice_a') === 'string' && this.checkURL(this.formData.get('choice_a'))) {
        this.formData.delete('choice_a')
      }

      if (typeof this.formData.get('choice_b') === 'string' && this.checkURL(this.formData.get('choice_b'))) {
        this.formData.delete('choice_b')
      }

      if (typeof this.formData.get('choice_c') === 'string' && this.checkURL(this.formData.get('choice_c'))) {
        this.formData.delete('choice_c')
      }

      if (typeof this.formData.get('choice_d') === 'string' && this.checkURL(this.formData.get('choice_d'))) {
        this.formData.delete('choice_d')
      }

      const image = this.questionEdit.name.split('"')
      image.forEach(item => {
        const img = item.split('/').pop()
        if (img.length === 44) {
          const carIndex = this.tempImage.indexOf(img)
          this.tempImage.splice(carIndex, 1)

          this.tempImage.forEach(async item => {
            await this.deleteStorage({ file: item })
          })
        }
      })
      await this.$services.ApiServices.update('questioncbt', this.formData, this.questionEdit.uuid).then(
        ({ data }) => {
          this.closeModalQuestion()
          this.showSnackbar({
            text: 'Update question cbt has successfully',
            color: 'success',
          })
        },
        err => console.error(err),
      )
      await this.getCbt(this.$route.params.uuid)
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.modalDialog = false
      this.closeModalQuestion()
    },
    confirmDestroyQuestion(uuid) {
      this.questionItem.uuid = uuid
      this.confirmDialog = true
    },
    async destroyQuestion() {
      this.isLoadingButton = true
      this.isDisabledButton = true
      await this.$services.ApiServices.destroy('questioncbt', this.questionItem.uuid).then(
        ({ data }) => {
          this.showSnackbar({
            text: data.result.message,
            color: 'success',
          })
        },
        err => console.error(err),
      )
      await this.getCbt(this.$route.params.uuid)
      this.isLoadingButton = false
      this.isDisabledButton = false
      this.confirmDialog = false
    },
    async importQuestion() {
      this.isLoadingButton = true
      this.formData = new FormData()
      this.formData.append('file', this.importFile)
      await this.$services.ImportParamsServices.import('importcbt', this.cbt.uuid, this.formData).then(
        async ({ data }) => {
          this.showSnackbar({
            text: 'Import question cbt has successfully',
            color: 'success',
          })
          await this.getCbt(this.$route.params.uuid)
        },
        err => {
          console.error(err)
          this.isLoadingButton = false
          this.modalDialogImport = false
        },
      )
      this.modalDialogImport = false
      this.isLoadingButton = false
    },
    async exportQuestion() {
      await this.$services.ExportServices.export('exportcbt').then(data => {
        const url = window.URL.createObjectURL(new Blob([data.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'template-cbt.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
    handleSwitch(event) {
      if (event !== false) {
        this.show_leaderboard = 1
      } else {
        this.show_leaderboard = 0
      }
    },
    closeFormQuestion(index) {
      this.questions.splice(index, 1)
      this.question.splice(index, 1)
      if (this.questions.length < 1) {
        this.isHide = false
      }
    },
    showSnackbar(snackbarData) {
      this.$store.dispatch('snackbar/showSnackbar', snackbarData)
    },

    closeModalQuestion() {
      this.questionEdit.name = ''
      this.questionEdit.choice_a = ''
      this.questionEdit.choice_b = ''
      this.questionEdit.choice_c = ''
      this.questionEdit.choice_d = ''
      this.questionEdit.answer.point = null
      this.questionEdit.answer.answer = ''
      this.questionEdit.file_a = null
      this.questionEdit.file_b = null
      this.questionEdit.file_c = null
      this.questionEdit.file_d = null
      this.modalDialog = false
      this.editAnswers = []
    },

    // Check If data is image link
    checkURL(url) {
      return url.match(/\.(jpeg|jpg|gif|png)$/) != null
    },
    isValidURL(isUrl) {
      let url
      try {
        url = new URL(isUrl)
        console.log(url)
      } catch (_) {
        return false
      }

      return true
    },
    // Check Answer Edit Question
    checkAnswerEdit(answer) {
      switch (answer) {
        case 'A':
          return this.questionEdit.choice_a == '' ? this.questionEdit.choice_a : this.questionEdit.file_a
        case 'B':
          return this.questionEdit.choice_b ? this.questionEdit.choice_b : this.questionEdit.file_b
        case 'C':
          return this.questionEdit.choice_c ? this.questionEdit.choice_c : this.questionEdit.file_c
        case 'D':
          return this.questionEdit.choice_d ? this.questionEdit.choice_d : this.questionEdit.file_d
      }
    },
  },
}
</script>

<style>
.match-height > [class*='col'] {
  display: flex !important;
  flex-flow: column !important;
}

.match-height > [class*='col'] > .v-card {
  flex: 1 1 auto !important;
}
</style>
